






































import { Component, Vue } from "vue-property-decorator";
import BaseLink from "../Base/BaseLink.vue";
import BaseWrapper from "../Base/BaseWrapper.vue";
import AppIcon from "../Others/AppIcon.vue";

@Component({
    components: {
        AppIcon,
        BaseWrapper,
        BaseLink,
    },
})
export default class LandingNavigationBar extends Vue {
    public is_transparent: boolean = true;

    public navigations: any[] = [
        { title: "Utama", hash: "hero" },
        { title: "Statistik", hash: "statistics" },
        { title: "Galeri", hash: "galleries" },
        { title: "Perusahaan", hash: "companies" },
    ];

    goTo(navigation: any) {
        this.$vuetify.goTo("#" + navigation.hash);
    }

    onScroll(e: any) {
        const scrollY = window.scrollY;

        this.is_transparent = !(scrollY > 0);
    }

    created() {
        window.addEventListener("scroll", this.onScroll);
    }
    destroyed() {
        window.removeEventListener("scroll", this.onScroll);
    }
}
